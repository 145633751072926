<template>

<button class="btn btn-default" @click="goTo('/')">Zurück zur Startseite</button>

<button class="btn btn-success" @click="reload">Neu laden</button>

<table class="table table-striped">
     <tr v-for="(zeit,tag) in getData" :key="zeit" >
       <td>{{enToDe(tag)}}</td>
       <td>{{secondsToString(zeit)}}</td>
     </tr>
    
</table>
</template>
<script>
import { ref, inject,computed } from "vue";
// import { useRoute } from 'vue-router'
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  /*

 -------------------http://10.10.0.25:8080/6172e141068a9210aa7889c6
  */

  setup() {
    const storage = inject("storage");
    const web = inject("web");
    const moment = inject("moment");

    let zeitenNachTag = ref(null);

    var user = storage.get("user");
    console.log("user", user);

   


    function reload() {
      web.post("/getZeitNachTag", {}, function (data) {

        zeitenNachTag.value = data.zeitenNachTag;
      });
    }
    reload();

    function goTo(target) {
      router.push({ path: target });
    }

    var getData = computed(() => {
      
      if(zeitenNachTag.value==null) return []

      return zeitenNachTag.value
    })
     function secondsToString(seconds) {
       return new Date(seconds * 1000).toISOString().substr(11, 8);
    }

    function enToDe(datum_en) {
       return moment(datum_en).format("DD.MM.YYYY")
    }





    return {
      goTo,
      zeitenNachTag,
      reload,
      secondsToString,
      getData,
      enToDe
      
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
template {
  padding: 20px;
}
.bold {
  font-weight: bold;
}
</style>