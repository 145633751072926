<template>
    <header>

    </header>

    

</template>








<script>




import router from '../router'
import { useStore  } from 'vuex'
import {ref,inject } from 'vue'

export default{
    setup(){


      
      // const web =inject("web")
      const storage =inject("storage")
      const store = useStore()

      let user=ref("")
      user.value=storage.get("user")


      let pleasTeleport = ref(false)
      let suchbegriff = ref("")
      let promotorenListe = ref([])
      let errors =( [])
           
        // pleasTeleport.value=!pleasTeleport.value;
        

        // web.get("/shortcuts",(data) =>{
        //   console.log(data);
        //   storage.set("shortcuts",data.shortcuts)
        // })

      

 function logout(){
          // LocalStorage leeren
          storage.delete("promoter")
          storage.delete("user")
          // Den User im Status entfenern
          store.state.user= false
          store.state.promoter=false
          // Umleitun
          router.push('/login')
        }


  // function amILoggedIn(){
  //    return store.getters.isLoggedIn
  //   }

      function goTo(target) {
        router.push({ path: target });
      }



      return{
        promotorenListe,
        // amILoggedIn,
        // counter: computed(() => store.state.counter),
        // user: computed(() => store.state.user),
        // $store : store,
        pleasTeleport,
        suchbegriff,
        errors,
        logout,
        user,
        goTo
      }
    },


    props:{
        'title' :{type:String,default:"NMope"}
    },
    

  
    components:{
        // Button
    }
  
}
</script>

<style scoped>




.router-link-active .router-link-exact-active {
    color:#00ce00;
}

/* nav a:hover, */
 nav a.router-link-active,
 nav a.router-link-exact-active {
   /* background-color: indianred; */
   text-decoration: underline;;
   cursor: pointer;
 }
 nav, .dropdown-menu{
   /* background-color:#fa931d  !important; */
   background-color:#FFF  !important;
   color:#333;
 }
 nav >*  a{
   color:#333 !important;
 }
 

 /** Wird teleportiert, ist aber trotzdem hier */
.cctable{
    border:0px;
}

.cctable td{
    padding:10px
}
</style>