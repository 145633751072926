// const BASE_URL = "https://do-it-activation.de/dashboard-api/api-dashboard.php";
// import axios from 'axios'
// import { notify } from "@kyvg/vue3-notification";

export default {
    log: console.log,



    isLoggedIn: () => {
        console.log("IS logged in in Helper.js")
        let userString = localStorage.getItem("user")
        // console.log("IsLogged in im Service",userString)
        if (typeof (userString) == "undefined") return false;
        if (userString === false) return false;
        let user = JSON.parse(userString)
        if (user !== null && typeof (user) != "undefined") {
            return true;
        }
        return false;
    },
    isNumeric: function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

   

}