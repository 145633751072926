<template>
  <!-- Medienzähler -->
  <img
    src="@/assets/img/venom.jpg"
    style="position: fixed; left: 0px; top: 0px; width: 100%"
  />
  <div
    style="z-index: 22; position: fixed; left: 110px; top: 30px; color: #cecece"
  >
    Heute: {{ secondsToString(summe_heute) }}
  </div>

  <div
    style="
      z-index: 22;
      position: fixed;
      left: 0px;
      top: 520px;
      width: 100%;
      text-align: center;
      background-color: #000;
      height: 100vh;
    "
  >
    <div v-if="zeit == null">
      <button class="btn btn-success" @click="startMediennutzung">
        Starte Mediennutzung
      </button>
    </div>
    <div v-if="zeit != null">
      <button class="btn btn-danger" @click="beendeMediennutzung">
        Mediennutzung beenden
      </button>
    </div>

    <button class="btn btn-default" @click="goTo('/uebersicht')">
      Zur Übersicht
    </button>
  </div>
  <div
    style="
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #111;
      z-index: 22;
    "
    @click="logout"
  >
    Logout
  </div>
</template>
<script>
import { ref, inject } from "vue";
// import { useRoute } from 'vue-router'
//   import { ElMessageBox } from 'element-plus';
import router from "../router";
export default {
  components: {},

  /*

 -------------------http://10.10.0.25:8080/6172e141068a9210aa7889c6
  */

  setup() {
    const storage = inject("storage");
    const moment = inject("moment");
    const web = inject("web");
    const store = inject("store");

    let zeit = ref(null);
    let summe_heute = ref(0);

    var user = storage.get("user");
    console.log("user", user);

    function startMediennutzung() {
      web.post("/startMediennutzung", {}, function (data) {
        console.log(data);
        reload();
      });
    }

    function beendeMediennutzung() {
      web.post("/beendeMediennutzung", {}, function (data) {
        console.log(data);
        reload();
      });
    }

    function reload() {
      web.post("/offeneZeit", {}, function (data) {
        // console.log("zeit ");
        // console.log(data.zeit);
        zeit.value = data.zeit;
      });
    }
    reload();

    refreshZeitVonHeute(true);
    setInterval(() => {
      refreshZeitVonHeute();
    }, 1000);

    function refreshZeitVonHeute(firstRequest) {
      //  console.log("zeit.value",zeit.value)
      if (zeit.value == null && firstRequest !== true) return;

      var heute = moment().format("YYYY-MM-DD");
      web.post("/getZeitNachTag", { datum: heute }, function (data) {
        summe_heute.value = data.zeitenNachTag;
      });
    }
    function goTo(target) {
      router.push({ path: target });
    }

    function secondsToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    }

    function logout() {
      // LocalStorage leeren
      storage.delete("promoter");
      storage.delete("user");
      // Den User im Status entfenern
      store.state.user = false;
      store.state.promoter = false;
      // Umleitun
      router.push("login");
    }

    return {
      secondsToString,
      goTo,
      zeit,
      startMediennutzung,
      beendeMediennutzung,
      summe_heute,
      logout
    };
  },
  mounted() {
    // alert(1)
  },
};
</script>

<style scoped>
template {
  padding: 20px;
}
.bold {
  font-weight: bold;
}
</style>